/* This example requires Tailwind CSS v2.0+ */
import '../css/style.css'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon ,ChevronRightIcon} from '@heroicons/react/24/outline'

import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faRocket } from '@fortawesome/free-solid-svg-icons';

const features = [
  {
    name: 'Video',
    description: 'Craft compelling video content to convey your brand message effectively.',
  },
  {
    name: 'Reel',
    description: 'Create short and engaging videos for social media platforms.',
  },
  {
    name: 'Photos',
    description:
      'Showcase your products or services with stunning photography.',
  },
  { name: 'Animation', description: 'Add dynamic animations to make your visuals more engaging and informative.' },
]
export default function Example() {
  return (
    <div className="">
      <Nav/>
      <br/>  <br/>
      {/* <div className="container mx-auto p-8">
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-4 text-white text-center">Video & Photography</h2>
        <p className="text-lg text-desccolor text-center">Bring your brand to life with captivating visuals:</p>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-2 text-white">Video: Craft compelling video content to convey your brand message effectively.</h3>
        <img src="https://cdn.prod.website-files.com/5fac161927bf86485ba43fd0/63bd908284529b20ce1504fc_Blog%20Cover_2022_12_10%20Best%20Content%20Creation%20Software%20for%20Video%20Creators%20in%202022.webp" alt="Video content"
         className="w-full  h-36 mb-4 rounded-sm" />
        <ul className="list-disc ml-6 text-desccolor">
          <li>Engage your audience with high-quality video production.</li>
          <li>Utilize storytelling techniques to highlight your brand's unique aspects.</li>
          <li>Incorporate professional editing to ensure a polished final product.</li>
          <li>Reach a wider audience through various video-sharing platforms.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-2 text-white">Reel: Create short and engaging videos for social media platforms.</h3>
        <img src="https://plugins-media.makeupar.com/smb/blog/post/2022-04-27/9839f784-3452-4e59-acc1-b929e3a4582f.jpg" alt="Reel content"          className="w-full  h-36 mb-4 rounded-sm" />
        <ul className="list-disc ml-6 text-desccolor">
          <li>Capture attention quickly with dynamic and creative reels.</li>
          <li>Showcase your products or services in a fun and memorable way.</li>
          <li>Leverage trending music and effects to enhance viewer engagement.</li>
          <li>Drive traffic to your website or social media profiles with captivating content.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-2 text-desccolor">Photos: Showcase your products or services with stunning photography.</h3>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsNI06slnFoWxyEn79ToDravMsMZJVX5p2HA&s" alt="Photography content"          className="w-full  h-36 mb-4 rounded-sm" />
        <ul className="list-disc ml-6 text-desccolor">
          <li>Highlight the details and quality of your offerings with professional shots.</li>
          <li>Create a cohesive visual identity that aligns with your brand.</li>
          <li>Use high-resolution images to ensure clarity and appeal across all platforms.</li>
          <li>Enhance your marketing materials with compelling and attractive visuals.</li>
        </ul>
      </div>
    </div> */}
  <div className="  m-4" id='Vediocontent'>
      <section aria-labelledby="features-heading" className="relative">
        <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-16">
          <img
            src="https://static.clideo.com/files/content/what-is-videographer.jpg"
            alt="Black leather journal with silver steel disc binding resting on wooden shelf with machined steel pen."
            className="h-full w-full object-center object-cover lg:h-full lg:w-full rounded-sm"
          />
        </div>

        <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:pb-32 sm:px-6 lg:max-w-7xl lg:pt-32 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="lg:col-start-2">
          
            <p className="text-4xl font-extrabold text-white tracking-tight">Video & Photography</p>
            <p className="mt-4 text-desccolor"> 
            Video & Photography Bring your brand to life with captivating visuals:
            </p>

            <dl className="mt-10 grid grid-cols-1 gap-y-10 gap-x-8 text-sm sm:grid-cols-2">
              {features.map((feature) => (
                <div key={feature.name}>
                  <dt className="font-medium text-white">{feature.name}</dt>
                  <dd className="mt-2 text-white">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </section>
      <section class="py-20" data-aos="zoom-out" data-aos-easing="ease" data-aos-duration="1000">
        <div class="container">
            <div class="flex items-end justify-between mb-10">
                <div class="max-w-2xl mx-auto text-center">
                    <h2 class="text-3xl font-medium capitalize text-white mb-4">Video & Photography</h2>
                    <p class="text-sm  text-desccolor font-medium">Bring your brand to life with captivating visuals through our 






<br/> expert video and photography services.</p>
                </div>
            </div>
            <meta name="description" content="Root Group IT offers cutting-edge videography services to enhance your brand's visual storytelling. Discover our professional video production solutions and transform your digital presence."/>

            <div class="flex items-center flex-wrap rounded-3xl bg-default-950/40 backdrop-blur-3xl">
                <div class="md:w-1/2 w-auto grow md:border-e border-b border-white/10">
                    <div class="sm:p-10 p-8 hover:bg-hoverbg">
                        <div class="inline-flex items-center justify-center h-16 w-16 bg-primary/10 text-primary rounded-xl mb-10 ">
                        <FontAwesomeIcon icon={faRocket} size="3x" className="text-yallow" />
                        </div>
                        <h2 class="text-2xl text-white font-medium">Video Production</h2>
                        <div class="">
                        <span class="inline-block w-40 h-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-3 h-1 mx-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-1 h-1 bg-yallow rounded-full"></span>
                    </div>
                        <p class="text-base  text-desccolor mb-6">Craft compelling video content to convey your brand message effectively. Our expert team collaborates with you to produce high-quality videos that resonate with your audience. From concept to final editing, we ensure every detail aligns with your vision. Create promotional videos, tutorials, interviews, and more with our services.</p>
                    </div>
                </div>

                <div class="md:w-1/2 w-auto grow border-b border-white/10">
                    <div class="sm:p-10 p-8 hover:bg-hoverbg">
                        <div class="inline-flex items-center justify-center h-16 w-16 bg-primary/10 text-primary rounded-xl mb-10">
                        <FontAwesomeIcon icon={faRocket} size="3x" className="text-yallow" />                        </div>
                        <h2 class="text-2xl text-white font-medium ">
                          
Reel Production</h2>
<div class="">
                        <span class="inline-block w-40 h-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-3 h-1 mx-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-1 h-1 bg-yallow rounded-full"></span>
                    </div>
                        <p class="text-base text-desccolor mb-6">Create short and engaging videos for social media platforms. Our team specializes in crafting dynamic and attention-grabbing reels that highlight your brand. We ensure each reel is optimized for maximum engagement, helping you connect with your audience and boost your social media presence effectively.</p>
                    </div>
                </div>

                <div class="md:w-1/2 w-auto grow border-b md:border-b-0 md:border-e border-white/10">
                    <div class="sm:p-10 p-8 hover:bg-hoverbg">
                        <div class="inline-flex items-center justify-center h-16 w-16 bg-primary/10 text-primary rounded-xl mb-10">
                        <FontAwesomeIcon icon={faRocket} size="3x" className="text-yallow" />                        </div>
                        <h2 class="text-2xl text-white font-medium ">Photography</h2>
                        <div class="">
                        <span class="inline-block w-40 h-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-3 h-1 mx-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-1 h-1 bg-yallow rounded-full"></span>
                    </div>
                        <p class="text-base text-desccolor mb-6">Showcase your products or services with stunning photography. Our professional photographers capture high-quality images that highlight the best aspects of your offerings. From product shoots to lifestyle photography, we ensure each photo aligns with your brand's aesthetic and enhances your visual appeal, making a lasting impression on your audience.</p>
                    </div>
                </div>

                <div class="md:w-1/2 w-auto grow border-white/10">
                    <div class="sm:p-10 p-8 hover:bg-hoverbg">
                        <div class="inline-flex items-center justify-center h-16 w-16 bg-primary/10 text-primary rounded-xl mb-10">
                        <FontAwesomeIcon icon={faRocket} size="3x" className="text-yallow" />                        </div>
                        <h2 class="text-2xl text-white font-medium ">Animation</h2>
                        <div class="">
                        <span class="inline-block w-40 h-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-3 h-1 mx-1 bg-yallow rounded-full"></span>
                        <span class="inline-block w-1 h-1 bg-yallow rounded-full"></span>
                    </div>
                        <p class="text-base text-desccolor mb-6">Add dynamic animations to make your visuals more engaging and informative. Our skilled animators create eye-catching animations that bring your ideas to life. Whether it's explainer videos, motion graphics, or animated logos, we ensure each animation enhances your brand's message and captivates your audience effectively.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
<WhyUs/>
<Footer/>
    </div>
  )
}
