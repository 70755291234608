/* This example requires Tailwind CSS v2.0+ */
import '../css/style.css'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon ,ChevronRightIcon} from '@heroicons/react/24/outline'

import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faRocket } from '@fortawesome/free-solid-svg-icons';

const features = [
  {
    name: 'Video',
    description: 'Craft compelling video content to convey your brand message effectively.',
  },
  {
    name: 'Reel',
    description: 'Create short and engaging videos for social media platforms.',
  },
  {
    name: 'Photos',
    description:
      'Showcase your products or services with stunning photography.',
  },
  { name: 'Animation', description: 'Add dynamic animations to make your visuals more engaging and informative.' },
]
export default function Example() {
  return (
    <div className="">
      <Nav/>
      <br/>  <br/>
      <section id="features" class="py-20">
        <div class="container">
            <div class="flex items-end justify-between mb-10">
                <div class="max-w-2xl mx-auto text-center">
                    <h2 class="text-3xl font-medium capitalize text-white mb-4">Design Services</h2>
                    <p class="text-sm  text-desccolor font-medium">Make a lasting impression with our professional design services:</p>
                </div>
            </div>
            <div class="grid lg:grid-cols-3 gap-6">
                <div class="space-y-6" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                    <div class="bg-default-950/40 hover:bg-hoverbg rounded-xl backdrop-blur-3xl border-s-2 border-yallow hover:-trandefault-y-2 transition-all duration-500">
                        <div class="p-10">
                            <i data-lucide="file-text" class="h-10 w-10 text-primary"></i>
                            <h3 class="text-2xl font-medium text-white mb-4 mt-8">Logo Design</h3>
                            <p class="text-sm text-desccolor font-medium mb-4">Create a memorable brand identity with a custom-designed logo.
</p>
                           
                        </div>
                    </div>
                    <div class="bg-default-950/40  hover:bg-hoverbg rounded-xl backdrop-blur-3xl border-s-2 border-yallow hover:-trandefault-y-2 transition-all duration-500">
                        <div class="p-10">
                            <i data-lucide="pen" class="h-10 w-10 text-primary"></i>
                            <h3 class="text-2xl font-medium text-white mb-4 mt-8">Banner Design</h3>
                            <p class="text-sm text-desccolor font-medium mb-4">Stand out online with eye-catching banners for your website or social media profiles.</p>
                            
                        </div>
                    </div>
                </div>

                <div class="space-y-6" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                    <div class="bg-default-950/40 hover:bg-hoverbg rounded-xl backdrop-blur-3xl border-s-2 border-yallow hover:-trandefault-y-2 transition-all duration-500">
                        <div class="p-10">
                            <i data-lucide="database" class="h-10 w-10 text-primary"></i>
                            <h3 class="text-2xl font-medium text-white mb-4 mt-8">Menu Design</h3>
                            <p class="text-sm text-desccolor font-medium mb-4"> Design visually appealing menus for your restaurant or cafe.</p>
                            
                        </div>
                    </div>
                    <div class="bg-default-950/40 hover:bg-hoverbg rounded-xl backdrop-blur-3xl border-s-2 border-yallow hover:-trandefault-y-2 transition-all duration-500">
                        <div class="p-10">
                            <i data-lucide="gitlab" class="h-10 w-10 text-primary"></i>
                            <h3 class="text-2xl font-medium text-white mb-4 mt-8">Business Card, eBook Cover, and Flex Banner Design</h3>
                            <p class="text-sm text-desccolor font-medium mb-4"> Leave a lasting impression with professionally designed business cards, capture attention with visually striking eBook covers, and create flexible, attention-grabbing flex banners for outdoor advertising.</p>
                           
                        </div>
                    </div>
                </div>

                <div class="space-y-6" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                    <div class="bg-default-950/40 hover:bg-hoverbg rounded-xl backdrop-blur-3xl border-s-2 border-yallow hover:-trandefault-y-2 transition-all duration-500">
                        <div class="p-10">
                            <i data-lucide="palette" class="h-10 w-10 text-primary"></i>
                            <h3 class="text-2xl font-medium text-white mb-4 mt-8">Magazine and Brochure Design</h3>
                            <p class="text-sm text-desccolor font-medium mb-4">Create visually stunning magazine layouts to showcase your content and inform and entice your audience with well-designed brochures. </p>
                           
                        </div>
                    </div>
                    <div class="bg-default-950/40 hover:bg-hoverbg rounded-xl backdrop-blur-3xl border-s-2 border-yallow hover:-trandefault-y-2 transition-all duration-500">
                        <div class="p-10">
                            <i data-lucide="case-sensitive" class="h-10 w-10 text-primary"></i>
                            <h3 class="text-2xl font-medium text-white mb-4 mt-8">Employee ID Card Design</h3>
                            <p class="text-sm  text-desccolor font-medium mb-4">Enhance professionalism with custom-designed employee identification cards.</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
<WhyUs/>
<Footer/>
    </div>
  )
}
