// App.js
import React from 'react';
import "../css/website-design.css";
import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"
const App = () => {
  return (
    <div className="">
          <Nav/>
      
          <div className="bg-blue-700 text-white p-8 min-h-screen" id="contentweb">
      <header className="text-center mb-8">
        <h1 className="text-5xl font-bold mb-4">Building CT Websites That Convert

</h1>
        <div className="flex justify-center space-x-4 text-lg">
          <span>App Dev</span>
          <span className=' text-yallow'>|</span>
          <span>New Sites</span>
          <span className=' text-yallow'>|</span>
          <span>Redesigns</span>
          <span className=' text-yallow'> |</span>
          <span>eCommerce Site</span>
          <span className=' text-yallow'>|</span>
          <span>One Page Site</span>
          <span  className=' text-yallow'>|</span>
          <span>Mobile Responsive Sites</span>
        </div>
      </header>
      <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8">
          Develop Robust E-commerce Platforms
        </h2>
        <p className="text-center text-lg mb-12">
          That offer seamless shopping experiences and drive sales. Our solutions are designed to meet the needs of both businesses and customers, including:
        </p>
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 p-4">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyilIVvg6sUOmS_fKu6ryoPtzyWjBnlxeTLg&s"
              alt="E-commerce"
              className="rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2 p-4">
            <div className=" bg-bgcolor  hover:bg-hoverbg  p-6 rounded-lg shadow-lg mb-4">
              <h3 className="text-2xl font-semibold mb-2">Custom Storefronts</h3>
              <p className="text-gray-700">
                Creating unique, branded online stores.
              </p>
            </div>
            <div className="bg-bgcolor  hover:bg-hoverbg p-6 rounded-lg shadow-lg mb-4">
              <h3 className="text-2xl font-semibold mb-2">Payment Gateway Integration</h3>
              <p className="text-gray-700">
                Supporting various payment methods for customer convenience.
              </p>
            </div>
            <div className="bg-bgcolor  hover:bg-hoverbg  p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-2">Inventory Management</h3>
              <p className="text-gray-700">
                Implementing systems to track and manage stock efficiently.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    
      <WhyUs/>
      <Footer/>
    </div>
  );
}

export default App;