/* This example requires Tailwind CSS v2.0+ */
import '../css/appdev.css'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon ,ChevronRightIcon} from '@heroicons/react/24/outline'

import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"
const services = [
    {
      title: "Search Engine Optimization (SEO)",
      description: "Boost your website's visibility and organic traffic.",
      image: "https://miro.medium.com/v2/resize:fit:3580/1*TtZ1bAE8DF-RNvBYYXQHgA.jpeg" // replace with actual image path
    },
    {
      title: "Social Media Marketing",
      description: "Engage and grow your audience across various social media platforms.",
      image: "https://assets-global.website-files.com/63d3b57853309967e8bd73bb/6435a89bd31a6a572be34add_social_media_marketing.png" // replace with actual image path
    },
    {
      title: "Google Advertising",
      description: "Reach potential customers through targeted Google Ads campaigns.",
      image: "https://thebusinessfame.com/wp-content/uploads/2023/09/5.24.22-google-ad-updates.png" // replace with actual image path
    },
    {
      title: "Facebook Advertising",
      description: "Harness the power of Facebook's vast user base with tailored ad campaigns.",
      image: "https://a.storyblok.com/f/264268/1300x800/12175a7357/how-to-run-multiple-ad-campaigns-on-facebook.png" // replace with actual image path
    },
    {
      title: "Instagram Advertising",
      description: "Showcase your brand's story and products to Instagram's visually-oriented audience.",
      image: "https://leadsdubai.com/wp-content/uploads/2021/08/FAQ_Instagram-Advertising.jpg" // replace with actual image path
    },
    {
      title: "TikTok Advertising",
      description: "Leverage the trendsetting platform to connect with a younger demographic.",
      image: "https://img-c.udemycdn.com/course/750x422/5089646_16c9_2.jpg" // replace with actual image path
    },
    {
      title: "Snapchat Advertising",
      description: "Capture the attention of millennials and Gen Z with creative Snapchat ads.",
      image: "https://entail-assets.com/mayple/6347fef8e6fe0504ee708203_snapchatads1_5f12b34d0f90d8db7255c09c4d073079_2000-1699775579945.jpg" // replace with actual image path
    },
  ];
  


export default function Example() {
  return (
    <div className="">
      <Nav/>
      <br/>  <br/>
      <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8 text-white">Digital Marketing</h1>
      <p className="text-center mb-12  text-desccolor">
        Unlock the full potential of your online presence with our digital marketing services:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index}
          className="shadow-md border-2 border-hoverbg text-white rounded-lg overflow-hidden transition duration-500 transform hover:scale-105   hover:bg-hoverbg hover:text-white"
          >
            <img className="w-full h-48 object-cover" src={service.image} alt={service.title} />
            <div className="p-6">
              <h2 className="text-xl font-bold mb-2">{service.title}</h2>
              <p className="text-gray-700">{service.description}</p>
            </div>
            <div className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-in-out"></div>

          </div>
        ))}
      </div>
    </div>
  

<WhyUs/>
<Footer/>
    </div>
  )
}
