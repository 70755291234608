

import React, { useState, useEffect } from 'react';

import '../css/service.css';


const collections = [
    {
      name: "IT Solutions",
      href: '#',
      imageSrc: 'https://kms-technology.com/wp-content/uploads/2022/04/iStock-912617272-scaled-1.jpg',
      imageAlt: 'Custom IT solutions that address your unique business challenges, from infrastructure setup to cybersecurity measures.',
      desc:"Custom IT solutions that address your unique business challenges, from infrastructure setup to cybersecurity measures."
    },
    {
      name: "Digital Marketing",
      href: '#',
      imageSrc: 'https://assets.entrepreneur.com/content/3x2/2000/20191112054904-FotoJet32.jpeg',
      imageAlt: 'Comprehensive strategies to boost your brand’s online presence and engagement.',
      desc:"Comprehensive strategies to boost your brand’s online presence and engagement."
    },
    {
      name: 'Mobile App Development',
      href: '#',
      imageSrc: 'https://digitalmasterpieces.com/wp-content/uploads/2023/02/shutterstock_572651239-1536x1024.jpg',
      imageAlt: 'User-friendly, high-performance apps for iOS and Android platforms, tailored to your specific needs.',
      desc:"User-friendly, high-performance apps for iOS and Android platforms, tailored to your specific needs."
    },
    {
        name: 'Database Systems',
        href: '#',
        imageSrc: 'https://www.certum.eu/en/wp-content/uploads/2020/08/Virutal_file.jpg',
        imageAlt: 'Efficient database solutions to manage, store, and secure your critical business data.',
        desc:"Efficient database solutions to manage, store, and secure your critical business data."
      },
  ]
  
  
  export default function Example() {




    return (
      <div className="relative bg-bgcolor  rounded-md ">
        {/* Background image and overlap */}
        <div aria-hidden="true" className="hidden absolute inset-0 sm:flex sm:flex-col  rounded-md ">
   
          <div className="flex-1 relative w-full bg-gray-800">
            <div className="absolute inset-0 overflow-hidden"   >
            <div class="overlay"></div>
              <img
                src="https://atr45.ru/upload/iblock/99e/99e62a2955b4cc466941f92c9a2891ad.jpg"
                alt=""
                className="w-full h-full object-center object-cover opacity-10 rounded-md "
              />
            </div>
          </div>
          <div   />
          <div
      
         className="w-full h-full md:0 lg:h-28   bg-yallow   opacity-70   "
       >
    
       </div>
        </div>
  
        <div className="relative max-w-3xl mx-auto pb-96 px-4 text-center sm:pb-0 sm:px-6 lg:px-8  ">
          {/* Background image and overlap */}
          <div aria-hidden="true" className="absolute inset-0 flex flex-col sm:hidden  ">
            <div className="flex-1 relative w-full bg-gray-800">
                
              <div className="absolute inset-0 overflow-hidden">
              <div class="overlay"></div>
                <img
                  src="https://atr45.ru/upload/iblock/99e/99e62a2955b4cc466941f92c9a2891ad.jpg"
                  alt=""
                  className="w-full h-full object-center object-cover opacity-10 rounded-md bg-bgcolor  "
                />
              </div>
              <div className="absolute inset-0 bg-gray-900 opacity-60 bg-bgcolor  " />
            </div>
            <div className="w-full bg-bgcolor " />
          </div>
          <div className="relative py-32 text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">Our Services
           </h1>
           

          </div>
        </div>
  
        <section >
         
          <div className="mt-[-120%]  md:mt-0 max-w-md mx-auto grid grid-cols-1 gap-y-6 px-4 sm:max-w-7xl sm:px-6 sm:grid-cols-4 sm:gap-y-0 sm:gap-x-6 lg:px-8 lg:gap-x-4 "
          
         
          
          >
            {collections.map((collection) => (
              <div
                key={collection.name}
                className="group relative h-96  rounded-lg shadow-xl sm:h-auto sm:aspect-w-4 sm:aspect-h-5"
          
           >
                <div>
                  <div aria-hidden="true" className="absolute inset-0 rounded-lg overflow-hidden ">
              
 
                  <div class="flip-card">
  <div class="flip-card-inner">
    
    <div class="flip-card-front flex flex-col-reverse">

      <img     src={collection.imageSrc}
                        alt={collection.imageAlt}        className="w-full h-full object-center object-cover opacity-90 bg-bgcolor"  />
    <div className='  absolute bg-yallow text-center  p-2   rounded-s-sm  opacity-90  w-full  font-bold text-xl'>{collection.name}</div>
    

    </div>
    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-20 bg-bgcolor" />

    <div class="flip-card-back">
    <div class="card-body mt-20">
      <h5  className=' font-bold text-2xl'>{collection.name}</h5>
 
      <p className='mt-1'>{collection.desc}</p>
    </div> 
    </div>
  </div>
</div>
</div> </div>
              </div>
            ))}
          </div>
        </section>

      
      </div>
    )
  }
  