




'use client'
import "../css/nav.css";
import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../../.././logo.svg';
const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-bgcolor">
      <header className="absolute inset-x-0 top-0 z-50 bg-bgcolor">
        <nav className="mx-auto   flex max-w-7xl items-center justify-between  p-3 lg:px-3" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className=" h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 ttext-white"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6 text-white" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-8">
    







 
          <a href="/" id='link' className="text-sm font-semibold leading-6 text-white  hover:text-bggold">
            Home
          </a>
          <a href="/app_Development"  id='link' className="text-sm font-semibold leading-6 text-white  hover:text-bggold">
          Web & Apps
          </a>
          <a href="/social_media_marketing" id='link'className="text-sm font-semibold leading-6 text-white  hover:text-bggold">
          Digital Marketing 

          </a>
          <a href="/video_Photo" id='link' className="text-sm font-semibold leading-6 text-white  hover:text-bggold">
          Video & Photography

          </a>
          <a href="/design" id='link' className="text-sm font-semibold leading-6 text-white  hover:text-bggold">
          Design
          </a>
          <a href="/about" id='link' className="text-sm font-semibold leading-6 text-white  hover:text-bggold">
          About
          </a>
    



          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a     href="/contact"  id='link' className="text-sm font-semibold leading-6   bg-hoverbg  ps-4 pr-4  rounded-md text-white   hover:no-underline">
            Contact 
            </a>
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden  d">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-bgcolor px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src={logo}
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-white"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-white">
                <div className="space-y-2 py-6">
               


    <a
        href="/"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white  hover:no-underline"
      >
         Home
      </a>
      <a
        href="/app_Development"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white  hover:no-underline"
      >
         Web & Apps
      </a>
      <a
        href="/social_media_marketing"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white  hover:no-underline"
      >
Digital Marketing
      </a>
      <a
        href="/video_Photo"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white  hover:no-underline"
      >
         Video & Photography
      </a>
      <a
        href="/design"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white  hover:no-underline"
      >
         Design
      </a>
 

      <a
        href="/about"
        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white  hover:no-underline"
      >
         About
      </a>
   

                </div>
                <div className="py-6">
                  <a
                      href="/contact"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:text-white"
                  >
               Contact
                  </a>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

  
    </div>
  )
}




