import '../css/footer.css'; // Import CSS file for styling


const navigation = {
    IT_Solutions: [
    { name: 'Windows System', href: '#' },
    { name: 'Mac System', href: '#' },
    { name: 'Server System', href: '#' },
    { name: 'System Management', href: '#' },
  ],
  web_App: [
    { name: 'App Development', href: '#' },
    { name: 'Database System', href: '#' },
    { name: 'Wesite Design', href: '#' },
    { name: 'Wesite Redesigns', href: '#' },
    { name: 'E-Commerce Sites ', href: '#' },
  ],
  Digital_Marketing: [
    { name: 'SEO', description: 'Boost your websites visibility and organic traffic.', href: '/#Vision'},
    { name: 'Social Media Marketing', description: 'Engage and grow your audience across various social media platforms. ', href: '/#Mission'},
    { name: 'Google Advertising', description: 'Reach potential customers through targeted Google Ads campaigns.', href: '/#Market'},
    { name: 'Facebook Advertising', description: 'Harness the power of Facebooks vast user base with tailored ad campaigns.', href: '/#who' },
    { name: 'Instagram Advertising', description: 'Showcase your brands story and products to Instagrams visually-oriented audience.', href: '/' },
    { name: 'TikTok Advertising', description: 'Leverage the trendsetting platform to connect with a younger demographic.', href: '/' },

    { name: 'Snapchat Advertising', description: 'Capture the attention of millennials and Gen Z with creative Snapchat ads.', href: '/' },

  ],
  Video_Photgraphy: [
    { name: 'Video', description: 'Craft compelling video content to convey your brand message effectively.', href: '/#Vision'},
    { name: 'Reel', description: ': Create short and engaging videos for social media platforms.', href: '/#Mission' },
    { name: 'Photos', description: 'Showcase your products or services with stunning photography.', href: '/#Market'},

  ],
  Design: [
    { name: 'Logo', description: 'Create a memorable brand identity with a custom-designed logo.', href: '/#Vision' },
    { name: 'Banner', description: 'Stand out online with eye-catching banners for your website or social media profiles. ', href: '/#Mission'},
    { name: 'Menu', description: 'Design visually appealing menus for your restaurant or cafe.', href: '/#Market' },
    { name: 'Business Card', description: 'Leave a lasting impression with a professionally designed business card.', href: '/' },
    { name: 'eBook Cover', description: 'Capture attention with visually striking eBook covers.', href: '/#Vision' },
    { name: 'Flex', description: 'Design flexible and attention-grabbing flex banners for outdoor advertising.', href: '/#Mission' },
    { name: 'Magazine', description: 'Create visually stunning magazine layouts to showcase your content.', href: '/#Market' },
    { name: 'Brochure', description: 'Inform and entice your audience with well-designed brochures.', href: '/' },
    { name: 'Employee Card', description: 'Enhance professionalism with custom-designed employee identification cards.', href: '/' },

  ],
  About: [
    { name: 'Why Choose Us: ', description: 'Discover the unique advantages of partnering with Root Group.', href: '/#Vision' },
    { name: 'Company Profile', description: 'A defined objective, the purpose that drives actions forward', href: '/#Mission'},
    { name: 'Case study', description: 'Arena of exchange, where goods and services meet demand', href: '/#Market' },
  
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/Roottgroup/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/roottgroup/',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },

 
  
  ],
}

export default function Example() {
  return (
    <footer className=" mt-32 juc pb-10" aria-labelledby="footer-heading" id="footer">

    
        
       
        <div className="container mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-yallow">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; 2024 Root Group 
          </p>
          
      </div>
    </footer>
  )
}
