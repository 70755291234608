
import '../css/Digital_Marketing.css'; // Import CSS file for styling
import React, { useState, useEffect } from 'react';
import cover from "../img/1.jpg"



const digitals = [

 
  {
  
    title: 'Search Engine Optimization ',
    desc: 'Increase your website’s visibility and ranking on search engines with our expert strategies. We focus on keyword optimization, on-page SEO, and link building to drive organic traffic.',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  {
  
    title: 'Content Marketing',
    desc: ' Create valuable, relevant content that attracts and retains your target audience. Our services include blog writing, video production, and infographic creation.',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },

  {
  
    title: 'Social Media Management',
    desc: ' Engage with your audience and build your brand on platforms like Facebook, Instagram, Twitter, and LinkedIn. We handle content creation, scheduling, and community management.',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },

 
  
  // More people...
]

export default function Example() {
  return (
    <section className='  md:mt-20 rounded-none'>
<div class="lg:max-w-lg">
          <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-white">Our Digital Marketing Services</h1>
          <div className='w-32 h-1 mt-2 bg-yallow rounded-md'></div>
          <p class="mt-6 text-xl leading-8  text-white">Maximize your online presence with our comprehensive digital marketing services. We specialize in:</p>
        
            </div>

<ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-3">
      {digitals.map((digital) => (
        <li key={digital.title} className="" id='cardsdigital'>

<div class="card-body">
      <h5  className=' font-bold text-2xl'>{digital.title}</h5>
 
      <p className='mt-1'>{digital.desc}</p>
    </div> 
        </li>
      ))}
    </ul>
    <div className=" relative overflow-hidden  mt-10 " >
        {/* Decorative background image and gradient */}
        <div aria-hidden="true" className="absolute inset-0">
        
          <div className="absolute inset-0  opacity-35" />
          <div className="absolute inset-0 bg-gradient-to-t from-bgcolor via-bgcolor" />
        </div>
  
        {/* Callout */}
        <section
          aria-labelledby="sale-heading"
          className="relative max-w-7xl mx-auto pt-22  px-4 flex flex-col items-center text-center sm:px-6 lg:px-8"
        >
          <div className="max-w-2xl mx-auto lg:max-w-none  pb-4 ">
            <h2
              id="sale-heading"
              className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl text-yallow"
            >
              Get 25% off during our
            </h2>
            <p className="mt-4 max-w-xl mx-auto text-xl text-white">
              Most of our products are limited releases that won't come back. Get your favorite items while they're in
              stock.
            </p>
           
          </div>
          
        </section>
        </div>

        
    </section>
  )
}
