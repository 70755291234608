/* This example requires Tailwind CSS v2.0+ */
import '../css/appdev.css'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon ,ChevronRightIcon} from '@heroicons/react/24/outline'

import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"


import img1 from "../img/img1.png"
const features = [
  {
    name: 'Native App Development',
    description:
      'Creating apps that leverage the full potential of iOS and Android features.',
    icon: ChevronRightIcon,
  },
  {
    name: 'Cross-Platform Development ',
    description:
      'Building apps that work seamlessly across different platforms.',
    icon: ChevronRightIcon,
  },
  {
    name: 'App Maintenance and Updates',
    description:
      'Providing ongoing support to keep your app running smoothly.',
    icon: ChevronRightIcon,
  },
  {
    name: 'UI/UX Design',
    description:
      'Crafting intuitive and engaging user interfaces to enhance the overall user experience.',
    icon: ChevronRightIcon,
  },
]
export default function Example() {
  return (
    <div className="">
      <Nav/>
      <br/>  <br/>
      <div className="text-center mt-5">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl text-white ">
           App Development & Design
          </p>
          <p className="max-w-xl mt-3 mx-auto text-xl  text-desccolor ">
          Your Trusted Company for Beautiful, Custom App Development Services          </p>
        </div>

        <div className=" flex items-center justify-center mt-4">
      <div className=" p-6 rounded-lg shadow-lg grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex justify-center items-center">
          <img 
            src={img1}
            alt="Placeholder" 
            className="rounded-lg"
          />
        </div>
        <div className="flex flex-col ">
          <h2 className="text-2xl font-bold mb-2 text-white">Building You An App That Converts</h2>
          <p className="text-desccolor text-xl">
          Are you looking to develop an app for your business? Look no further! Our Connecticut App Design & Development team at Root Group is here to assist you in creating a functional, mobile-friendly, aesthetically pleasing, and user-friendly app tailored to your business needs.
             </p>

             <p className="text-desccolor mt-4 text-md">
             Root Group is proud to announce the expansion of our professional services to include app development. We specialize in custom application design and development for both large and small businesses. Whether you are a local or national client, our CT app development service is ready to meet your needs. At Root Group, we take pride in our ability to work with a diverse range of businesses, ensuring we find a budget that works for you.             </p>
       
       
             <p className="text-desccolor mt-4 text-md">
             Throughout the app design process, we collaborate closely with you to make sure all your requirements and preferences are fully met. We highly value your input to achieve the best possible outcome. Trust Root Group as your go-to mobile app development agency. Let’s work together to bring your vision to life.
             </p>
        </div>
      </div>

      
    </div>
  
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          App Development
          </p>
          <p className="mt-6 text-lg leading-8 text-desccolor">
          Our end-to-end mobile app development services cover everything from concept to launch. We build high-performance apps for both iOS and Android platforms, ensuring user satisfaction and functionality. Services include:
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg text-white">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                 <p className='text-white'> {feature.name}</p>
                </dt>
                <dd className="mt-2 text-base leading-7  text-desccolor">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>


<WhyUs/>
<Footer/>
    </div>
  )
}
