import React, { useState } from 'react';

import '../css/partner.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from "../img/partner/1.png"
import img2 from "../img/partner/2.png"
import img3 from "../img/partner/3.png"
import img4 from "../img/partner/4.png"

import img5 from "../img/partner/5.png"
import img6 from "../img/partner/6.png"
import img7 from "../img/partner/7.png"
import img8 from "../img/partner/8.png"
import img9 from "../img/partner/9.png"

export default function Example() {
    

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 968,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
      <div className="  " id='partnerdiv'>
   
   <div className="  ">
        <div className="mx-auto max-w-7xl px-9 lg:px-8">
          <h2 className="text-3xl  text-center font-extrabold tracking-tight text-bggold sm:text-4xl text-white"
          >
           Our Clients
          </h2>
         
          
        </div>
      </div>
      <div className="container  ">
              <Slider {...settings} className="h-full w-full    mt-3">
               
    
                  <div className="p-5 rounded-full" >
                  <div className=" grid ">
            <a href="https://ozbag.com/" target="_blank" id='cercle'>
              <img
              className="col-span-2 object-contain lg:col-span-1 rounded-full "
              src={img1}
              alt="Transistor"
              width={158}
              height={48}
         
            /></a>
 
            
         
          </div>                  </div>
          <div className="p-5 rounded-full" >
                  <div className=" grid ">
            <a href="https://ozbag.com/" target="_blank" id='cercle'>
              <img
              className="col-span-2 object-contain lg:col-span-1  rounded-full"
              src={img2}  
                          alt="Transistor"
              width={158}
              height={48}
         
            /></a>
 
            
         
          </div>                  </div>
          <div className="p-5 rounded-full" >
                  <div className=" grid ">
            <a href="https://ozbag.com/" target="_blank" id='cercle'>
              <img
              className="col-span-2 object-contain lg:col-span-1  rounded-full"
              src={img9}  
                          alt="Transistor"
              width={158}
              height={48}
         
            /></a>
 
            
         
          </div>                  </div>
          <div className="p-5 rounded-full" >
                  <div className=" grid ">
            <a href="https://ozbag.com/" target="_blank" id='cercle'>
              <img
              className="col-span-2 object-contain lg:col-span-1  rounded-full"
              src={img3}
              alt="Transistor"
              width={158}
              height={48}
         
            /></a>
 
            
         
          </div>                  </div>
          <div className="p-5 rounded-full" >
                  <div className=" grid ">
            <a href="https://ozbag.com/" target="_blank" id='cercle'>
              <img
              className="col-span-2 object-contain lg:col-span-1 rounded-full "
              src={img4}
              alt="Transistor"
              width={158}
              height={48}
         
            /></a>
 
            
         
          </div>                  </div>
          <div className="p-5 rounded-full" >
                  <div className=" grid ">
            <a href="https://ozbag.com/" target="_blank" id='cercle'>
              <img
              className="col-span-2 object-contain lg:col-span-1 rounded-full "
              src={img5}
              alt="Transistor"
              width={158}
              height={48}
         
            /></a>
 
            
         
          </div>                  </div>

            <div className="p-5 rounded-full" >
                  <div className=" grid ">
            <a href="https://ozbag.com/" target="_blank" id='cercle'>
              <img
              className="col-span-2 object-contain lg:col-span-1 rounded-full "
              src={img6}
              alt="Transistor"
              width={158}
              height={48}
         
            /></a>
 
            
         
          </div>                  </div>
               
                  {/* Add more logos as needed */}
              </Slider>
          </div>
 
      </div>
    )
  };
  

  