
import Nav from "./js/nav"
import Cover from "./js/cover"
import Digital_Marketing from "./js/Digital_Marketing"
import OurServices from "./js/Our Services"
import WhyUs from "./js/WhyUs"
import Partner from "./js/partner"
import Footer from "./js/footer"
function App() {
  return (
    <div className="">
<meta name="description" content="Root Group IT provides innovative technology solutions to drive your business forward. Discover our expert IT services and transform your digital landscape."/>

     <Nav   />
     <br/>  <br/>
     <Cover  />
     <div className=" container-md mx-auto  ">
     <div className=" container mx-auto  ">
     <Digital_Marketing/><br/><br/>
        <OurServices className=""/>
        <br/>   <br/>
        
        <WhyUs/>
     
        <Partner/>
        </div>
     </div>
     <Footer/>
    </div>
  );
}

export default App;
