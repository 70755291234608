// App.js
import React from 'react';
import "../css/website-design.css";
import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"
const App = () => {
  return (
    <div className="">
          <Nav/>
      
          <div className="bg-blue-700 text-white p-8 min-h-screen" id="contentweb">
      <header className="text-center mb-8">
        <h1 className="text-5xl font-bold mb-4">Building CT Websites That Convert

</h1>
        <div className="flex justify-center space-x-4 text-lg">
          <span>App Dev</span>
          <span className=' text-yallow'>|</span>
          <span>New Sites</span>
          <span className=' text-yallow'>|</span>
          <span>Redesigns</span>
          <span className=' text-yallow'> |</span>
          <span>eCommerce Site</span>
          <span className=' text-yallow'>|</span>
          <span>One Page Site</span>
          <span  className=' text-yallow'>|</span>
          <span>Mobile Responsive Sites</span>
        </div>
      </header>
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-2/3 p-4">
         
          <p className="text-lg mb-4">
            Your CT business needs a website. But you need more than a website.
            You need a site that looks good, is mobile-friendly, can be easily edited
            and converts customers. Our web design team offers exactly that.
          </p>
          <p className="text-lg mb-4">
            The Mack Media Group designs and develops custom websites for large and
            small businesses throughout Connecticut. It doesn’t matter if you’re a
            national company or a local mom and pop shop. We have web design options
            that fit your budget and business goals.
          </p>
          <p className="text-lg">
            We work with you throughout the process, listening to your input and creating
            the exact website you are looking for. We are your trusted CT web design & 
            development agency.
          </p>
        </div>
        <div className="md:w-1/3 p-4">
          <img
            src="https://99designs-blog.imgix.net/blog/wp-content/uploads/2022/01/104228210.jpg?auto=format&q=60&fit=max&w=930"
            alt="Web Design"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>
      <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8">Create a Brand-New Website</h2>
        <p className="text-center text-lg mb-12">From scratch, tailored to your business goals and aesthetics. We focus on:</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className=" bg-hoverbg p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">Brand Alignment</h3>
            <p className="text-gray-700">Ensuring your website reflects your brand identity.</p>
          </div>
          <div className="bg-hoverbg  p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">Functionality</h3>
            <p className="text-gray-700">Incorporating features that enhance user interaction and engagement.</p>
          </div>
          <div className="bg-hoverbg  p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">Scalability</h3>
            <p className="text-gray-700">Designing sites that can grow with your business.</p>
          </div>
        </div>
      </div>
    </div>
    </div>

    
      <WhyUs/>
      <Footer/>
    </div>
  );
}

export default App;