/* This example requires Tailwind CSS v2.0+ */
import '../css/style.css'
import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon ,ChevronRightIcon} from '@heroicons/react/24/outline'
import React, { useState } from 'react';


import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faRocket } from '@fortawesome/free-solid-svg-icons';

import emailjs from 'emailjs-com'; // Import EmailJS

export default function Example() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.send(
        'service_9fiwj3p',       // Replace with your EmailJS service ID
        'template_uvme911',      // Replace with your EmailJS template ID
        formData,
        'MvJgEaiBEil5nU6hr'           // Replace with your EmailJS user ID
      );

      if (result.text === 'OK') {
        setStatus('Email sent successfully!');
        setFormData({ firstName: '', lastName: '', email: '', phone: '', message: '' });
      } else {
        setStatus('Failed to send email.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('An error occurred.');
    }
  };

  return (
    <div className="">
      <Nav />
      <br /> <br />
      <section id="contact" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="grid lg:grid-cols-3 gap-6 items-center">
            <div>
              <div>
                <span className="text-sm uppercase font-semibold tracking-wider text-white mb-6">Contact Us</span>
              </div>
              <h2 className="text-3xl md:text-4xl font-semibold mt-4 text-white">Get Your Free Quote</h2>

              <div className="flex flex-col sm:flex-row items-center gap-5 text-center sm:text-left mt-10">
                <div className="h-12 w-12 rounded-full bg-bgcolor flex items-center justify-center">
                  <i className="bi bi-envelope text-2xl text-yallow"></i>
                </div>
                <div>
                  <h5 className="text-base font-medium mb-1 text-white">info@rootgroup.tech</h5>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row items-center gap-5 text-center sm:text-left mt-10">
                <div className="h-12 w-12 rounded-full bg-bgcolor flex items-center justify-center">
                  <i className="bi bi-telephone text-2xl text-yallow"></i>
                </div>
                <div>
                  <h5 className="text-base font-medium mb-1 text-white">(+964)7831158434</h5>
                </div>
              </div>
            </div>

            <div className="lg:col-span-2 lg:ms-24">
              <div className="p-6 md:p-12 rounded-md shadow-lg bg-hoverbg">
                <form onSubmit={handleSubmit}>
                  <div className="grid sm:grid-cols-2 gap-6">
                    <div>
                      <label htmlFor="formFirstName" className="block text-sm font-semibold text-white mb-2">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        className="block bg-bgcolor text-white w-full text-sm rounded-md py-3 px-4 border-hoverbg focus:border-hoverbg focus:ring-transparent"
                        id="formFirstName"
                        placeholder="Your first name..."
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="formLastName" className="block text-sm font-semibold text-white mb-2">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        className="block w-full bg-bgcolor text-white text-sm rounded-md py-3 px-4 border-hoverbg focus:border-hoverbg focus:ring-transparent"
                        id="formLastName"
                        placeholder="Your last name..."
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="formEmail" className="block text-sm font-semibold text-white mb-2">Email Address</label>
                      <input
                        type="email"
                        name="email"
                        className="block w-full bg-bgcolor text-white text-sm rounded-md py-3 px-4 border-hoverbg focus:border-hoverbg focus:ring-transparent"
                        id="formEmail"
                        placeholder="Your email..."
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div>
                      <label htmlFor="formPhone" className="block text-sm font-semibold text-white mb-2">Phone Number</label>
                      <input
                        type="text"
                        name="phone"
                        className="block w-full bg-bgcolor text-white text-sm rounded-md py-3 px-4 border-hoverbg focus:border-hoverbg focus:ring-transparent"
                        id="formPhone"
                        placeholder="Type phone number..."
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <div className="mb-4">
                        <label htmlFor="formMessages" className="block text-sm font-semibold text-white mb-2">Messages</label>
                        <textarea
                          name="message"
                          className="block bg-bgcolor text-white w-full text-sm rounded-md py-3 border-hoverbg focus:border-hoverbg px-4 border-gray-200 focus:border-gray-300 focus:ring-transparent"
                          id="formMessages"
                          rows="4"
                          placeholder="Type messages..."
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button type="submit" className="py-2 px-6 rounded-md text-base items-center justify-center border border-none text-white bg-bgcolor hover:bg-hoverbg transition-all duration-500 font-medium">
                      Send Messages <i className="bi bi-send ms-1"></i>
                    </button>
                  </div>
                </form>
                {status && <p className="mt-4 text-white">{status}</p>}
              </div>
            </div>
          </div>
        </div>
      </section>

      <WhyUs />
      <Footer />
    </div>
  );
}
