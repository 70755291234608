/* This example requires Tailwind CSS v2.0+ */
import '../css/style.css'

import Nav from "../../home/js/nav"

import WhyUs from "../../home/js/WhyUs"
import Footer from "../../home/js/footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faRocket } from '@fortawesome/free-solid-svg-icons';
import img from "../imge/aboutt.png"
export default function Example() {
  return (
    <div className="">
      <Nav/>
      <br/>  <br/>
      <section id="about" class="py-10">
        <div class="container">

            <div class="grid lg:grid-cols-2 items-center gap-6">
                <div class="lg:ms-5 ms-8 mt-[-40px]" >
                    
                    <h2 class="text-3xl text-white font-semibold  ">About Root Group</h2>
                    <p class="text-base font-normal  mt-6 text-desccolor">At Root Group, we specialize in providing comprehensive solutions to elevate your business's digital presence and operational efficiency. Our expert team delivers tailored services, from digital marketing and design to IT solutions and mobile app development, ensuring that your brand stands out in the competitive market. We are committed to driving growth and success for our clients through innovative and effective strategies.</p>

                    <div class="grid lg:grid-cols-3 grid-cols-1 gap-8 mt-9">

                        <div class=" text-white">
                            <div class="flex items-center justify-start">
                                <div
                                    class="items-center justify-center flex bg-green-50 rounded-full h-20 w-20 border border-dashed border-green-50">
                                                      <FontAwesomeIcon icon={faRocket} size="3x" className="text-yallow" />

                                </div>
                            </div>
                            <h1 class="text-xl  font-semibold pt-6">1. Expertise</h1>
                            <p class="text-base text-desccolor font-normal mt-2">Seasoned professionals delivering high-quality, tailored solutions.</p>
                        </div>

                        <div class=" text-white">
                            <div class="flex items-center justify-start">
                                <div
                                    class="items-center justify-center flex bg-red-50 rounded-full h-20 w-20 border border-dashed border-red-50">
                                                         <FontAwesomeIcon icon={faRocket} size="3x" className="text-yallow" />

                                </div>
                            </div>
                            <h1 class="text-xl font-semibold  pt-6">2. Customer</h1>
                            <p class="text-base text-desccolor font-normal mt-2">Prioritizing client needs with personalized, trust-based relationships.</p>
                        </div>

                        <div class=" text-white">
                            <div class="flex items-center justify-start">
                                <div
                                    class="items-center justify-center flex bg-primary/10 rounded-full h-20 w-20 border border-dashed border-primary/10">
                                                            <FontAwesomeIcon icon={faRocket} size="3x" className="text-yallow" />

                                </div>
                            </div>
                            <h1 class="text-xl font-semibold pt-6">3. Proven Results</h1>
                            <p class="text-base  text-desccolor font-normal mt-2">Success stories and testimonials showcasing measurable growth.</p>
                        </div>
                    </div>
                </div>

                <div class="flex items-center">
                    <img src={img} class=" rounded-xl mx-auto object-cover"  alt='' />
                </div>

            </div>
        </div>
    </section>

  
<WhyUs/>
<Footer/>
    </div>
  )
}
