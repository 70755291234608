

import React, { useState, useEffect } from 'react';

import '../css/WhyUs.css';



const ServicesSection = () => {
    return (
        <div className="flex flex-col  " >
            <div className="  w-full py-[70px]  pr-4" id='cardwhycontent'> 
                <div className="container z-1">
                    <div className="grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-4  justify-center">                        
                        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 ">
                            <div className="w-full relative mb-10">                                
                                <div className="flex-auto p-4">                                   
                              
                                    <div className="text-center mb-5 -mt-10">
                                    <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">Why Us?</h1>
                                    <br/>
                                   
                                        <h6 className=" text-white text-lg font-medium">Discover why businesses trust Root Group</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-10 mb-4 items-center">
                        <div className="col-span-10 sm:col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 me-0 md:me-0 lg:-me-7 xl:-me-7">
                        <div className="bg-white dark:bg-gray-800/40 rounded-md w-full relative transform translate-y-0 hover:-translate-y-4 duration-500 ease-in-out mb-8 sm:mb-8 lg:mb-0"  id='cardwhy'>
            <span className="inline-flex absolute left-[50%] start-[50%] -translate-x-[50%] -translate-y-[50%] justify-center items-center h-[80px] w-[80px] rounded-full bg-primary-50 dark:bg-gray-900 border-[5px] border-solid border-bgcolor bg-yallow dark:border-slate-800">
            <i className="bi bi-1-circle-fill display-3 text-bgcolor"></i>

            </span>
            <div className="flex-auto text-center p-8 md:p-14">                                    
                <h2 className="font-bold text-2xl capitalize tracking-wide text-center my-4 text-gray-800 dark:text-white">
                Expertise
                </h2> 
                <div className="text-center text-slate-700 dark:text-slate-400 text-lg">
                    <p>seasoned professionals brings extensive experience and knowledge to every project.</p>
                </div>
               
            </div>
        </div>
                        </div>
                        <div className="col-span-10 sm:col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 me-0 md:me-0 lg:-me-7 xl:-me-7">
                        <div className="bg-white dark:bg-gray-800/40 rounded-md w-full relative transform translate-y-0 hover:-translate-y-4 duration-500 ease-in-out mb-8 sm:mb-8 lg:mb-0" id='cardwhy'>
            <span className="inline-flex absolute left-[50%] start-[50%] -translate-x-[50%] -translate-y-[50%] justify-center items-center h-[80px] w-[80px] rounded-full bg-primary-50 dark:bg-gray-900 border-[5px] border-solid border-bgcolor bg-yallow dark:border-slate-800">
            <i className="bi bi-2-circle-fill display-3 text-bgcolor"></i>

            </span>
            <div className="flex-auto text-center p-8 md:p-14">                                    
                <h2 className="font-bold text-2xl capitalize tracking-wide text-center my-4 text-gray-800 dark:text-white">
                Centric Approach
                </h2> 
                <div className="text-center text-slate-700 dark:text-slate-400 text-lg">
                    <p>We prioritize our clients’ needs and deliver personalized solutions.
</p>
                </div>
               
            </div>
        </div>
                        </div>
                        <div className="col-span-10 sm:col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 me-0 md:me-0 lg:-me-7 xl:-me-7">
                        <div className="bg-white dark:bg-gray-800/40 rounded-md w-full relative transform translate-y-0 hover:-translate-y-4 duration-500 ease-in-out mb-8 sm:mb-8 lg:mb-0" id='cardwhy'>
            <span className="inline-flex absolute left-[50%] start-[50%] -translate-x-[50%] -translate-y-[50%] justify-center items-center h-[80px] w-[80px] rounded-full bg-primary-50 dark:bg-gray-900 border-[5px] border-solid border-bgcolor bg-yallow dark:border-slate-800">
            <i className="bi bi-3-circle-fill display-3 text-bgcolor"></i>

            </span>
            <div className="flex-auto text-center p-8 md:p-14">                                    
                <h2 className="font-bold text-2xl capitalize tracking-wide text-center my-4 text-gray-800 dark:text-white">
                Innovative Solutions
                </h2> 
                <div className="text-center text-slate-700 dark:text-slate-400 text-lg">
                    <p>We leverage the latest technologies to provide cutting-edge solutions.

</p>
                </div>
               
            </div>
        </div>
                        </div>
                        <div className="col-span-10 sm:col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-3 me-0 md:me-0 lg:-me-7 xl:-me-7">
                        <div className="bg-white dark:bg-gray-800/40 rounded-md w-full relative transform translate-y-0 hover:-translate-y-4 duration-500 ease-in-out mb-8 sm:mb-8 lg:mb-0" id='cardwhy'>
            <span className="inline-flex absolute left-[50%] start-[50%] -translate-x-[50%] -translate-y-[50%] justify-center items-center h-[80px] w-[80px] rounded-full bg-primary-50 dark:bg-gray-900 border-[5px] border-solid border-bgcolor bg-yallow dark:border-slate-800">
            <i className="bi bi-4-circle-fill display-3 text-bgcolor"></i>

            </span>
            <div className="flex-auto text-center p-8 md:p-14">                                    
                <h2 className="font-bold text-2xl capitalize tracking-wide text-center my-4 text-gray-800 dark:text-white">
                Proven Track Record
                </h2> 
                <div className="text-center text-slate-700 dark:text-slate-400 text-lg">
                    <p>Our success stories and client testimonials speak to our ability to deliver results.
</p>
                </div>
              
            </div>
        </div>
                        </div>
                    
                       
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default ServicesSection;