
import '../css/cover.css'; // Import CSS file for styling
import React, { useState, useEffect } from 'react';
import img1 from "../img/1.jpg";
import img2 from "../img/2.jpg";
import img3 from "../img/3.jpg";


function Slideshow() {
    const [progress, setProgress] = useState(0);
    const [isActive, setIsActive] = useState(true);
   
    useEffect(() => {
      const interval = setInterval(() => {
        // Increment progress by 1% every second
        setProgress((prevProgress) => {
          let newProgress = prevProgress + 20;
          // If progress reaches 100%, clear the interval
         
          if (newProgress > 330) 
            newProgress=0;
            ;
        
          return newProgress;
        });
      }, 900); // Interval of 1 second
      return () => clearInterval(interval); // Cleanup on unmount
    }, []);
    
  return (
    <div>
      
  


  <div id="carouselExampleIndicators " class="carousel slide " data-ride="carousel">
  <ol class="carousel-indicators " id='carouselindicators'>

  <li data-target="#carouselExampleIndicators " data-slide-to="0" className=' active ' id="line" >


  {progress <= 100 &&
         <div
         style={{
        width: `${progress}%`,
           height: '4px',
           background: '#FFBB00',
           transition: 'width 0.5s ease-in-out',
         }}
         className='rounded-m'
       >
    
       </div>
      }
 
        

    </li>

    <li data-target="#carouselExampleIndicators" data-slide-to="1"  id="line">
    { progress <= 200  && progress > 100 &&
         <div
         style={{
        width: `${progress-100}%`,
           height: '4px',
           background: '#FFBB00',
           transition: 'width 0.5s ease-in-out',
         }}
         className='rounded-md'
       >
    
       </div>
      }
    </li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"  id="line">
    { progress <= 300  && progress > 200 &&
         <div
         style={{
        width: `${progress-200}%`,
           height: '4px',
           background: '#FFBB00',
           transition: 'width 0.5s ease-in-out',
         }}
         className='rounded-md'
       >
    
       </div>
      }
    </li>

  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
    <div class="carousel-caption" id='caption'>
        <h3 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" 
       
        >  Building your Business with Digital Marketing
        </h3>
        <p className="mt-4 text-xl text-gray-500" 
      
        >  Your dedicated partners in business growth for the digital world
        .</p>
      </div>
      <img class="d-block w-100" src={img1} id="imgcover"  alt="First slide"/>
    </div>
    <div class="carousel-item">
    <div class="carousel-caption" id='caption'>
        <h3 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" 
       
        >  Building  Websites 

        </h3>
        <p className="mt-4 text-xl text-gray-500" 
      
        >   We are your trusted Company web design & development agency. </p>
      </div>
      <img class="d-block w-100" src={img2} id="imgcover"  alt="Second slide"/>
    </div>
    <div class="carousel-item">
    <div class="carousel-caption" id='caption'>
        <h3 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" 
       
        >  Start Your Digital Marketing Success  </h3>
        <p className="mt-4 text-xl text-gray-500" 
      
        >  It's time to make your mark in the digital space. Let's get started!</p>
      </div>
      <img class="d-block w-100" src={img3} id="imgcover" alt="Third slide"/>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>




    </div>
  );
}

export default Slideshow;
